@import './style/_variables.scss';
@import './style/_normalize.scss';
@import './style/_theme.scss';
@import './style/_button.scss';
@import './style/_form.scss';
@import './style/_layout.scss';
@import './style/_utilities.scss';
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600;700&display=swap');

@font-face {
  font-family: 'Yellowtail';
  src:
    local('Yellowtail-Regular'),
    url('./fonts/Yellowtail-Regular.ttf') format('truetype');
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin: 0px auto;
  padding-top: 80px;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.footer {
  padding: 1rem;
  text-align: center;
}
