.SubscriptionBenefitsContainer {
  display: inline-grid;

  .lineItem {
    display: inline-flex;
    margin-bottom: 20px;

    .iconImage {
      width: 25px;
      height: 25px;
      margin-right: 8px;
      margin-top: 4px;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }

  p {
    margin: 0;
    margin-top: 2px;
  }
}
