header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 48px;
  background-color: #fff;
  z-index: 300;
  .headerContainer {
    position: relative;
    height: 60px;
    width: 100%;
    top: 0px;
    margin: 0px auto;
    max-width: 1200px;
  }
  .logo {
    top: -4px;
    position: absolute;
    left: -30px;
    width: 200px;
    display: block;
    line-height: 48px;
    height: auto;
    vertical-align: middle;
    &.center {
      top: 36px;
      display: inline-block;
      padding: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    img {
      width: 100px;
      line-height: 48px;
      height: auto;
      vertical-align: middle;
    }
  }
  .menu {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.7);
    &:hover {
      color: #000;
    }
  }
  .logout {
    float: right;
    margin-right: 20px;
  }
}
