.helpMenuContainer {
  user-select: none;
  position: relative;

  .helpTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    padding: 10px 0px;

    .helpDetails {
      display: flex;
      align-items: center;
      justify-content: center;

      .helpArrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17px;
        height: 17px;
        margin: 0 0.3rem;
      }
    }
  }

  .helpOptionsContainer {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    visibility: hidden;
    background: #fef1e2;
    z-index: 1;

    .helpOption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0px;
      cursor: pointer;
    }

    &.is-active {
      visibility: visible;
    }
  }
}
