.login-wrapper {
  background:
    url('../img/background.svg') repeat,
    #fcf4ed;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  .imageContainer {
    overflow: hidden;
    max-width: 500px;
    font-size: 0;
    line-height: 0;
    border-radius: 3px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      font-size: 0;
      line-height: 0;
    }
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  .login-container {
    max-width: 320px;
    padding: 10px 5px;
    text-align: center;
    .logo {
      padding: 0;
      margin-bottom: 10px;
      img {
        width: 130px;
        left: -10px;
        position: relative;
        line-height: 48px;
        padding: 0;
        height: auto;
        vertical-align: middle;
      }
    }
    .button {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .hr {
      position: relative;
      margin: 10px 0px;
      opacity: 0.2;
      p {
        position: relative;
        z-index: 2;
        display: inline;
        padding: 0px 10px;
        font-size: 11px;
        text-transform: uppercase;
        background: #fcf4ed;
      }
      div {
        position: absolute;
        top: 50%;
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        background: #333;
      }
    }
    .errorMessage {
      font-size: 16px;
      color: #555;
      padding: 7px 0;
      margin: 20px 0;
      line-height: 30px;
      border: 2px solid #555;
      border-right: 0;
      border-left: 0;
      animation: message 0.3s ease-in-out;
    }
    a {
      display: inline-block;
      width: 100%;
      margin: 10px 0px;
      color: #333;
    }
  }
}
