body {
  background-color: #fff;
  font-family: 'Lato', sans-serif;
  color: #333;
  font-size: 14px;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
h1 {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.8em;
  color: #555;
  letter-spacing: 3px;
  margin: 10px 0px;
}
h2 {
  font-weight: normal;
  display: block;
  font-family: 'Lato', sans-serif;
  text-transform: inherit;
  position: relative;
  z-index: 9;
  font-size: 1.4em;
  line-height: 1.6em;
  letter-spacing: 2px;
  padding: 0;
}
h3 {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
}
p {
  letter-spacing: 1px;
  font-size: 1.1em;
  line-height: 1.9em;
  &.bold {
    font-weight: bold;
  }
}
ul {
  li {
    line-height: 1.4em;
  }
}
a {
  color: #18b2bb;
  text-decoration: none;
  outline: 0;
  @media (min-width: 1200px) {
    &:hover {
      text-decoration: underline;
    }
  }
}
hr {
  height: 1px;
  background-color: #eee;
  border: 0px;
  width: 100%;
}
.Page {
  max-width: 1024px;
  margin: 0 auto;
  padding: 80px 40px;
  text-align: center;
  &__title {
  }
  &__image {
    margin-bottom: 30px;
  }
  &__price {
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
  }
}
.decoratedImg {
  width: 200px;
  border-radius: 100%;
  height: 200px;
  object-fit: cover;
  border: 4px solid #fff;
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.3);
  margin: 10px 0;
}
button.link {
  background: none;
  cursor: pointer;

  border: none;
  font-size: 0.9em;
  color: #333;
  text-decoration: underline;
}
