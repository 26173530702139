section {
  .username {
    display: inline-block;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &.intro-slide {
    display: flex;
    align-content: center;
    // background-color: #fcf4ed;
    max-width: 480px;
    margin: 0px auto 0px;
    // border-radius: 10px;
    .vertical-center {
      align-self: center;
    }
    .button {
      background-color: #18b2bb;
      max-width: 280px;
      font-size: 14px;
      margin: 0px 0px 10px 0px;
    }
    @media (max-width: 320px) {
      // font-size: 13px;
      background-position: top center;
      height: auto;
    }
    @media (min-width: 1280px) {
      background-position: 100% 80%;
    }
  }
}
