.shopSection {
  .loading-container {
    height: 100%;
    align-self: center;

    p {
      text-transform: uppercase;
    }
  }
}
.shop-container {
  max-width: 1200px;
  flex: 1;
  margin: auto;
  margin-top: 60px;
  position: relative;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  @media only screen and (max-width: 600px) {
    body {
      background-color: lightblue;
    }
  }
  h1 {
    margin: 10px 0px;
  }
  .shop-categories-section {
    display: flex;
    padding: 5px;
    flex-direction: row;
    justify-content: space-evenly;
    .category {
      cursor: pointer;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      .image-container {
        width: 80px;
        height: 80px;
        border-radius: 100px;
        aspect-ratio: 1;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.6s ease-in-out;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .title {
      }
      p {
        margin: 0px;
      }
      &:hover {
        color: #19b3bb;
      }
    }
  }
  .productsWrapper {
    > span {
      display: flex;
      gap: 10px;
      background-color: #19b3bb;
      flex-wrap: wrap;
      &:last-child {
        justify-content: space-around;
        flex-wrap: wrap;
      }
    }
    .category {
      display: flex;
      gap: 10px;
      background-color: aquamarine;
      flex-wrap: wrap;
      &:last-child {
        justify-content: space-around;
        flex-wrap: wrap;
      }
    }
  }
  .productsCategoryWrapper {
    .categoryTitle {
      position: relative;
      gap: 0px;
      padding-bottom: 20px;
      p {
        color: #333;
        font-size: 40px;
        font-family: 'YellowTail';
        padding-bottom: 0%;
        margin: 0px;
        margin-bottom: -20px;
      }
      .underlineContainer {
        margin-left: auto;
        margin-right: auto;
      }
      img {
        width: 200px;
      }
    }
    .products {
      gap: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(calc(30% - 10px), 1fr));
      @media screen and (max-width: 600px) {
        grid-template-columns: repeat(auto-fill, minmax(calc(40% - 10px), 1fr));
      }
    }
  }
  .product {
    font-size: 0.9em;
    margin: 0px auto;
    text-align: left;
    width: 100%;
    a {
      color: #333;
      text-decoration: none;
      line-height: inherit;
      justify-self: center;
      display: grid;
      width: 100%;
      text-align: left;
      margin-bottom: 10px;
      @media screen and (max-width: 480px) {
        margin-bottom: 0px;
      }
    }
    .imageWrapper {
      overflow: hidden;
      border-radius: 30px;
      aspect-ratio: 1;
      width: 100%;
      height: 100%;
      background-color: #efefef;
      @media screen and (max-width: 600px) {
        aspect-ratio: 0.7;
      }
    }
    img {
      transition: transform 0.6s ease-in-out;
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1;
      @media screen and (max-width: 600px) {
        aspect-ratio: 0.7;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
    p {
      margin: 0px;
      padding: 0px;
      span {
        width: 100%;
      }
    }
    .productShort {
      display: flex;
      padding: 5px;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 8px;
      .title {
        color: #333;
        font-size: 20px;
        line-height: 150%;
        margin-bottom: 8px;
      }
      .subTitle {
        color: #333;
        font-size: 14px;
        line-height: 150%;
      }
      .moreLink {
        color: rgba(0, 0, 0, 0.75);
        text-decoration: underline;
      }
      .price {
        width: auto;
        display: inline-block;
        color: #19b3bb;
        font-size: 18px;
      }
      @media screen and (max-width: 480px) {
        flex-direction: column;
        flex-wrap: wrap;
        .title,
        .price {
          font-size: 1.4em;
        }
      }
    }
    &.wide {
      width: 100%;
      .productShort {
        padding: 10px 0px;
        gap: 10px;
        flex-direction: column;
        margin-top: 8px;
        .title {
          font-size: 22px;
          line-height: 28px;
        }
        .price {
          color: #19b3bb;
        }
      }
    }
    .buyButton {
      text-align: center;
      max-width: 400px;
      font-size: 16px;
      line-height: 30px;
      background-color: #19b3bb;
      color: white;
      font-weight: 900;
      margin-top: 30px;
    }
  }
  .productDetail {
    font-size: 0.9em;
    max-width: 1200px;
    margin: 0px auto;
    text-align: left;

    a {
      color: #333;
      text-decoration: none;
      line-height: inherit;
      justify-self: center;
      display: grid;
      width: 100%;
      text-align: left;
      margin-bottom: 10px;
      @media screen and (max-width: 480px) {
        margin-bottom: 0px;
      }
    }
    .topSection {
      display: flex;
      gap: 34px;
      @media screen and (max-width: 900px) {
        display: block;
      }
    }
    .bottom-section {
      .section-title {
        margin-top: 20px;
        position: relative;
        gap: 0px;
        padding-bottom: 20px;
        text-align: center;
        .h1-title {
          color: #333;
          font-size: 40px;
          font-family: 'YellowTail';
          padding-bottom: 0%;
          margin: 0px;
          line-height: 1em;
        }
        .underlineContainer {
          margin-left: auto;
          margin-right: auto;
        }
        img {
          aspect-ratio: 20;
          height: 20px;
          border-radius: 0px;
          object-fit: fill;
        }
      }
    }
    .productDescription {
      flex: 1;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .title {
        color: #333;
        font-size: 3.125rem;
        line-height: 150%;
      }
      .price {
        width: auto;
        display: inline-block;
        color: #19b3bb;
        font-size: 38px;
      }
      p {
        font-size: 1.125rem;
        line-height: 150%;
      }
      .variants-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .variant-item {
          border-radius: 8px;
          border-width: 1px;
          border-style: solid;
          border-color: rgba(51, 51, 51, 0.25);
          padding: 10px;
          display: flex;
          cursor: pointer;
          max-width: 400px;

          img {
            height: 25px;
            width: 25px;
            margin-right: 10px;
          }
          &.selected {
            background-color: #c3f3e3;
            border-color: #19b3bb;
          }
          .name {
            flex: auto;
            color: #555;

            &.selected {
              color: #19b3bb;
            }
          }
          .price {
            color: #555;
            &.selected {
              color: #19b3bb;
            }
          }
        }
      }
      .formats {
        .title {
          color: #333;
          font-size: 1.4rem;
          padding-bottom: 8px;
        }
        p {
          font-size: 1.125rem;
          line-height: 150%;
        }
      }
      .photoSets {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(calc(20%), 1fr));
        gap: 10px;

        .photoSet {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          img {
            height: 100px;
          }
          .title {
            color: #333;
            font-size: 1rem;
            text-align: center;
          }
          .label {
            color: #333;
            font-size: 0.75rem;
            line-height: 150%;
            text-align: center;
          }
        }
      }
      .delivery {
        .title {
          color: #333;
          font-size: 1.4rem;
          padding-bottom: 18px;
        }
        .sub-title {
          padding-bottom: 20px;
        }
        .delivery-list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .list-item {
            display: flex;
            flex-direction: row;
            gap: 8px;
            border-top-width: 0.5px;
            border-top-color: #555;
            border-top-style: solid;
            padding-top: 10px;

            max-width: 400px;
            p {
              font-size: 1rem;
            }
            .name {
              flex: auto;
            }
          }
        }
      }
      .specifications {
        .title {
          color: #333;
          font-size: 1.4rem;
          padding-bottom: 18px;
        }
        .sub-title {
          padding-bottom: 20px;
        }
        .specifications-list {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .specifications-list-item {
            display: flex;
            flex-direction: row;
            gap: 8px;
            img {
              height: 50px;
              width: 50px;
              margin-right: 20px;
              border-radius: 0px;
            }
            .detail {
              display: flex;
              flex-direction: column;
              gap: 0px;
              .title {
                font-size: 1.125rem;
                line-height: 150%;
                font-weight: bold;
                padding-bottom: 4px;
              }
              .label {
                font-size: 1rem;
                line-height: 150%;
              }
            }
          }
        }
      }
    }
    .testimonial-list {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 50px;
      .testimonial {
        padding-top: 10px;
        .stars {
          display: flex;
          gap: 5px;
          justify-content: center;
          .star {
            height: 25px;
            filter: invert(56%) sepia(91%) saturate(395%) hue-rotate(134deg) brightness(87%) contrast(93%);
          }
        }
        .user {
          color: #333;
          font-weight: bold;
          font-size: 1.2rem;
          text-align: center;
        }
        .title {
          color: #333;
          font-weight: bold;
          font-size: 1.2rem;
          text-align: center;
        }
        .label {
          color: #333;
          font-size: 0.75rem;
          line-height: 150%;
          text-align: center;
          margin-bottom: 20px;
        }
        border-width: 1.5px;
        border-style: solid;
        border-radius: 8px;
        padding-left: 50px;
        padding-right: 50px;
        min-height: 100px;
        width: 100%;
        flex: 45%;
        border-color: lightgray;
        margin-bottom: 0px;
        @media screen and (max-width: 900px) {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
    .product-images {
      height: max-content;
      position: -webkit-sticky;
      position: sticky;
      width: 100%;
      max-width: 550px;
      @media screen and (max-width: 900px) {
        position: static;
      }
      top: 60px;
      .imageWrapper {
        overflow: hidden;
        border-radius: 30px;
        height: 550px;
        background-color: #efefef;
        max-width: 550px;
        // width: 100%;
      }
      .video-container {
        height: 550px;
        max-width: 550px;
        video {
          display: inline-block;
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 30px;
          background-color: #efefef;
        }
      }
      .image-container {
        img {
          height: 100%;
        }
      }
      .preview-images {
        max-width: 550px;

        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-top: 14px;
        flex-wrap: wrap;
        justify-content: flex-start;
        .preview-image {
          height: 100px;
          width: 100px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
            &:hover {
              transform: scale(1);
            }
          }
        }
        .preview-video {
          height: 100px;
          width: 100px;
          background-color: #efefef;
          border-radius: 8px;
          position: relative;
          cursor: pointer;
          .video-play-icon {
            position: absolute;
            background-color: #ffffff;
            width: 20px;
            bottom: 4px;
            left: 4px;
          }
          video {
            display: inline-block;
            object-fit: cover;
            overflow: auto;
            width: 100px;
            height: 100px;
            border-radius: 8px;
          }
        }
      }
    }
    img {
      transition: transform 0.6s ease-in-out;
      max-height: 550px;
      object-fit: cover;
      aspect-ratio: 1;
      overflow: hidden;
      border-radius: 30px;
      @media screen and (max-width: 900px) {
        max-width: 100%;
        max-height: unset;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
    p {
      margin: 0px;
      padding: 0px;
      span {
        width: 100%;
      }
    }
    .productShort {
      display: flex;
      padding: 5px;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 8px;
      .subTitle {
        color: #333;
        font-size: 14px;
        line-height: 150%;
      }
      .moreLink {
        color: rgba(0, 0, 0, 0.75);
        text-decoration: underline;
      }
      .price {
        width: auto;
        display: inline-block;
        color: #19b3bb;
        font-size: 28px;
        font-weight: bold;
      }
      @media screen and (max-width: 480px) {
        flex-direction: column;
        flex-wrap: wrap;
        .title {
          font-size: 1em;
        }
        .price {
          font-size: 0.8em;
        }
      }
    }
    &.wide {
      width: 100%;
      .productShort {
        padding: 10px 0px;
        gap: 10px;
        flex-direction: column;
        margin-top: 8px;
        .title {
          font-size: 22px;
          line-height: 28px;
        }
        .price {
          color: #19b3bb;
        }
      }
    }
    .button {
      display: grid;
    }
    .buyButton {
      text-align: center;
      max-width: 400px;
      margin-top: 0px;
      font-size: 16px;
      line-height: 30px;
      background-color: #19b3bb;
      color: white;
      font-weight: 900;
      flex: 1;
    }
  }
}
