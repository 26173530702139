.photoEditorWrapper {
  background-color: #f5f3f2;
  height: 100vh;
  margin: 0 auto;
}
.loading-wrapper {
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-content: stretch;
}

.fullFrame {
  position: relative;
  box-shadow: -25px 0px 20px rgba(0, 0, 0, 0.5);
  // TODO change back 2s
  animation-duration: 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  // margin-top: -20px;
  opacity: 0;
  border: 45px solid transparent;
  border-image-slice: 30;
  border-image-width: 72px;
  border-image-outset: 0;
  border-image-repeat: round;
  .upper-canvas,
  .lower-canvas {
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  &.loaded {
    animation-name: loaded;
    animation-timing-function: cubic-bezier(0.55, 0, 0.95, 1.65);
  }
  &.brownFrame {
    border-image-source: url('./images/frame-brown.png');
  }
  &.whiteFrame {
    border-image-source: url('./images/frame-white.png');
  }
  &.lightgreyFrame {
    border-image-source: url('./images/frame-lightgrey.png');
  }
  &.blackFrame {
    border-image-source: url('./images/frame-black.png');
  }
  &.naturalFrame {
    border-image-source: url('./images/frame-natrual.png');
    border-image-slice: 90 !important;
  }
  @keyframes loaded {
    0% {
      opacity: 1;
      transform: scale(0.6) translateX(-1000px);
    }
    30% {
      opacity: 1;
      transform: scale(0.6) translateX(-1000px);
    }
    40% {
      opacity: 1;
      transform: scale(0.6) translateX(0px);
    }
    70% {
      opacity: 1;
      transform: scale(0.6) translateX(0px);
      filter: blur(0);
    }
    100% {
      opacity: 1;
      transform: scale(1) translateX(0);
    }
  }
}

.content {
  position: relative;
  background-color: white; /* or any background color */
  z-index: 2;
  width: calc(100% - 90px); /* Adjust the width to fit inside the frame */
  height: calc(100% - 90px); /* Adjust the height to fit inside the frame */
  margin: auto; /* Center the content */
}
.blackFrame {
  background: #222222;
}
.whiteFrame {
  background: #fbfafa;
}
.fullFrame.whiteFrame {
  border-image-slice: 90 !important;
}
.lightgreyFrame {
  background: #617279;
}
.frameSelected {
  border: 2px solid #18b2bb !important;
}
.naturalFrame {
  background: url('./images/naturalRight.png');
  background-size: cover;
}

.brownFrame {
  background: url('./images/brown-pattern-h.png');
  background-size: cover;
}

.actionBar {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: #fff;
  position: fixed;
  top: 0;
}
.actionBarTitle {
  margin: 0 auto;
}
.backBtn {
  background: url('./images/back.png') no-repeat;
  border: 0px;
  padding-left: 20px;
  height: 30px;
  width: 50px;
  background-position: center;
}
.backBtn:active,
.backBtn:focus {
  outline: none;
  box-shadow: none;
  background: none;
}
.saveBtn {
  border: 0px;
  background-color: rgb(255, 255, 255);
  color: rgb(24, 178, 187);
  padding-right: 20px;
}
.frameWrapper {
  display: flex;
  flex-direction: column;
  // flex-flow: row;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 85px);
  position: absolute;
  top: 65px;
  width: 100%;
}
footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0;
  z-index: 2;
  background: #f5f3f2;

  &:not(.default) {
    background-color: #fff;
    border: 1px solid rgb(240, 240, 240);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: 20px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  }
}
.footerTitleWrapper {
  display: flex;
  margin: 0 10px;
  padding-left: 20px;
}

.footerTitle {
  display: flex;
  margin: 0 auto;
  align-items: center;

  .titleImage {
    width: 30px;
    margin-right: 5px;
  }
}
.footerContent {
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  height: 100%;
}
.footerContent img.menuItemImg {
  width: 38px;
  height: 38px;
}
.footerContent .menuItemText {
  margin: 7px 0;
}
.frameColorMenu {
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.frameColor {
  border: 1px solid #000;
  border-radius: 100%;
  width: 38px;
  height: 38px;
}
.collageItem {
  cursor: pointer;
  padding-bottom: 9px;
}
.collageItem.selected .collageImg {
  filter: hue-rotate(180deg); /* Example effect for selected state */
  // background-color: green;
}

.collage-rect {
  fill: #ff5733; /* Selected state color */
}
.pointer {
  cursor: pointer;
}
.close {
  font-size: 20px;
}

.modal-alert {
  position: fixed;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 12px 30px 12px 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  border-radius: 60px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 1.5em;
  }
}

.modalContent p {
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
}

.modalContent {
  .btnCancel {
    background: none;
    color: #999;
    text-decoration: underline;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
@media (max-width: 800px) {
  .modal-alert {
    width: 80%;
  }
}
@media (max-height: 700px) {
  .fullFrame {
    box-shadow: -10px 0px 20px rgba(0, 0, 0, 0.5);
  }
  .topFrame {
    height: 10px;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    z-index: 10;
  }
  .bottomFrame {
    height: 10px;
    bottom: -10px;
    width: calc(100% + 20px);
    left: -10px;
    z-index: 10;
  }

  .leftFrame {
    left: -10px;
    width: 10px;
  }
  .rightFrame {
    width: 10px;
    right: -10px;
    height: 100%;
  }
}

.loading-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-image: url('./images/tail-spin.svg');
  background-size: cover;
  z-index: 1;
}

.font-list-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: stretch;
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow-x: scroll;
  padding-left: 20px;
  padding-right: 20px;
  transform: translateY(-70%);
  height: 70px;

  &.android {
    transform: none !important;
    position: fixed !important;
    z-index: 10000 !important;
    top: 140px !important;
  }

  &.desktop {
    transform: none !important;
    position: fixed !important;
    z-index: 10000 !important;
    bottom: 0 !important;
    height: 52px !important;
  }
}

.font-list-item-wrapper {
  margin-left: 5px;
}

.font-list-item {
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 20px;
  padding: 10px 20px;
  line-height: 18px;
  margin: 0 0 12px 12px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.active {
    color: #0cbcc7;
    background: #c3f3e3;
    border-color: #0cbcc7;
  }
}

.sideSelector {
  display: flex;
  justify-content: center;
  position: relative;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 2;
}

.sideSelectorContainer {
  background: #e2d5cd;
  border-radius: 5px;
  color: #000;
}

.sideBtn {
  color: #000;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background: #e2d5cd;
  width: 110px;
  height: 34px;
}

.sideBtn.active {
  background-color: #fff;
}
.cardFace {
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  .upper-canvas,
  .lower-canvas {
    filter: drop-shadow(-2px 2px 4px rgba(0, 0, 0, 0.25));
  }
}
.cardFront {
  z-index: 2;
}

.cardBack {
  transform: rotateY(180deg);
}

.frameWrapper {
  perspective: 1000px;
}

.frameWrapper.flipped .cardFace {
  transform: rotateY(180deg);
}
.cardFront,
.cardBack {
  backface-visibility: hidden;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  text-align: center;

  p {
    margin: 16px 0;
    color: #666;
  }

  .retry-button {
    padding: 8px 32px;
    height: 32px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 32px;
    width: 200px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;

    &:hover {
      background-color: #333;
    }
  }
}
