.invoiceLayout {
  position: fixed;
  top: 0px;
  z-index: 500;
  background: #fff;
  width: 100%;
  .close {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 56px;
    z-index: 1000;
    cursor: pointer;
  }
}
.invoicesContainer {
  height: 100vh;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 70px 10px 0px 10px;
  text-align: center;
  .invoices {
    max-width: 640px;
    margin: 0px auto;
    min-width: 300px;
    width: 100%;
    .invoice {
      cursor: pointer;
      &:hover {
        background: #c3f3e3;
      }
    }
    tr {
      line-height: 36px;
    }
    table,
    tbody {
      border-collapse: collapse;
      border: 1px solid #eee;
      margin: 0px auto;
      // max-width: 480px;
      width: 100%;
    }
    th {
      background-color: #fcf4ed;
      font-weight: bold;
      // width: 100%;
    }
    td,
    th {
      text-align: left;
      padding: 5px 5px 5px 10px;
      // width:33%;
      &:first-child {
        max-width: 80px;
        // width:10%;
      }
    }
    td {
      &:first-child {
        // background:#c3f3e3;
      }
    }
  }
}
