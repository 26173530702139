* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
html {
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
section {
  position: relative;
  text-align: center;
  .section-header {
    position: sticky;
    background: rgba(#fcf4ed, 0.8);
    backdrop-filter: blur(10px);
    top: 60px;
    z-index: 100;
    width: 100%;
    padding: 10px 0px;
    margin: 10px 0px 20px 0px;
    h2 {
      line-height: normal;
      margin: 0px;
      text-transform: uppercase;
      font-size: 1.4em;
      display: inline-block;
      margin: 0 auto;
    }
    small {
      margin: 4px auto;
      color: #999;
      display: block;
    }
  }
  .border {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    height: 60px;
  }
}
.app-container {
  position: relative;
}
.flex-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  .flex-container {
    width: 480px;
    padding: 10px;
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    @media screen and (max-width: 480px) {
      padding: 10px 20px;
    }
  }
}
