.selectLanguageContainer {
  user-select: none;
  .language {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    // border:1px solid rgba(0,0,0,0.3);
    padding: 10px 0px;
    .languageDetails {
      display: flex;
      align-items: center;
      justify-content: center;
      .languageArrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17px;
        height: 17px;
        margin: 0 0.3rem;
        .icon {
          font-size: 16px;
        }
      }
    }
  }
  .languagesContainer {
    visibility: hidden;
    // border-top: 1px solid black;
    .language {
      // opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
    &.is-active {
      visibility: visible;
    }
  }
}
