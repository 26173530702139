.confirm {
  width: 280px;
  border-radius: 8px;
  .close {
    fill: #555;
    width: 20px;
    &:hover {
      fill: #333;
    }
  }
  .container {
    padding-top: 30px;
    padding-left: 4px;
    padding-right: 4px;
    align-items: center;
    display: flex;
    flex-direction: column;
    p {
      /* Body */

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 1px;
      /* Light/Text */
      color: #333333;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      button {
        border: 0;
        width: 90px;
        border-radius: 30px;
        height: 30px;
        font-family: 'Josefin Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin: 10px 4px;
        &:hover {
          filter: brightness(95%);
        }
        &:focus {
          outline: 0 !important;
        }
        &.cancel {
          background: #cccccc;
          color: #555;
        }
        &.confirm {
          background: #c3f3e3;
          color: #333;
        }
      }
    }
  }
}
