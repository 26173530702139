.menu {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  z-index: 2;
  @media (max-width: 757px) {
    width: 100%;
  }
  .notification {
    background: #18b2bb;
    border-radius: 100px;
    width: 8px;
    height: 8px;
    display: block;
    position: absolute;
    overflow: hidden;
    top: 2px;
    z-index: 8;
    right: 3px;
  }
  .icon {
    font-size: 35px;
  }
  .trigger {
    position: absolute;
    top: 16px;
    right: 15px;
    // text-align: right;
    font-size: 37px;
    line-height: 35px;
    z-index: 13;
    width: 35px;
  }
}

.menu-wrapper {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  height: 100vh;
  background:
    url('../../img/background.svg') repeat,
    #fcf4ed;
  top: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  ul {
    margin-top: 62px;
    list-style-type: none;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    li {
      display: block;
      text-align: left;
      &.menuLogo {
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-bottom: 10px;
        img {
          opacity: 1;
          width: 100px;
        }
      }
      a {
        color: #333;
        line-height: 30px;
        text-transform: capitalize;
      }
    }
    @media (max-width: 480px) {
      gap: 2px;
    }
  }
}
