.circleMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 10px;
  padding: 10px 0px;
  max-width: 480px;
  margin: 60px auto 0px auto;
  // background:#fcf4ed;
  position: relative;
  z-index: 0;
  border-bottom: 1px solid #eee;
  &__item {
    a {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 100px;
      align-items: center;
      gap: 10px;
      text-align: center;
      overflow: hidden;
      color: #333;
      text-decoration: none;
      .imageHolder {
        width: 80px;
        height: 80px;
        border-radius: 100px;
        overflow: hidden;
        z-index: -1;
      }
      img {
        transition: transform 0.6s ease-in-out;
        width: 85px;
        height: 85px;
        overflow: hidden;
        object-fit: cover;
        background: #fcf4ed;
      }
      .name {
        text-align: center;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 1px;
        font-weight: bold;
      }
      &:hover {
        .imageHolder {
          width: 80px;
          height: 80px;
          border-radius: 100px;
          overflow: hidden;
        }
        img {
          transform: scale(1.2);
        }
        color: #18b2bb;
      }
    }
  }
}
