.plus-icon {
  border-radius: 3px;
  background: #676871;
  font-weight: lighter;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 19px;
  display: inline-block;
  margin: 0 5px;
  zoom: 0.8;
  color: #fff;
  text-align: center;
}
