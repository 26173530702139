.notify {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  padding: 10px;
  margin: 10px 0;
  line-height: 24px;
  font-size: 1em;
  text-align: center;
  background: #fff;
  width: 80%;
  box-shadow: 0 5px 13px rgba(0, 0, 0, 0.5);

  &__close {
    position: absolute;
    font-size: 1.4em;
    width: 15px;
    right: 5px;
    top: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 99%;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid #fff;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
}

.icon-ios {
  font-size: 20px;
  margin: 0 5px;
  color: #4f8ff8;
}
