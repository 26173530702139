.fallback-slide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eee;
  z-index: 99;
  overflow-y: scroll;

  .flex-wrapper {
    padding-top: 30px;
    flex-direction: column;
    min-height: 100vh;
    height: auto;

    .flex-container {
      align-items: flex-start;
      text-align: left;
      width: 320px;

      a {
        font-size: inherit;
        text-decoration: underline;

        &.button {
          display: block;
          width: auto;
          text-decoration: none;
        }
      }

      .error-code {
        padding: 20px;
        border-radius: 5px;
        color: #fff;
        margin: 10px 0;
        background: #999;
      }
    }
  }
}
