.zoomedImage {
  display: grid;
  grid-template-rows: minmax(250px, 600px);
  grid-template-columns: minmax(250px, 600px);
  align-content: center;
  justify-content: center;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.75);
  z-index: 200;
  padding: 0px;

  &.hidden {
    height: 0px;
    width: 0px;
    padding: 0px;
    border: 0px;
    display: hidden;
  }

  img {
    object-fit: contain;
    margin: auto;
    width: 100%;
    height: 100%;
  }
}
