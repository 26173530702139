.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #333;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  color: #fff;
  font-size: 16px;
}

.modal-header-cropper {
  justify-content: space-between;
  height: 60px;
  z-index: 10;
  background: #333333;
}
.crop-btn,
.cancel-btn {
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.btn {
  color: #18b2bb;
  background: none;
  border: none;
  font-size: 14px;
  padding: 0px 9px;
  cursor: pointer;
  padding-right: 22px;
}
.back-btn {
  background: url('./images/cropperBack.png') no-repeat;
  border: none;
  background-position: center;
  cursor: pointer;
  width: 41px;
}
.cropper-wrapper {
  color: #18b2bb;
  background: none;
  border: none;
  font-size: 16px;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.loading-wrapper {
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-content: stretch;
}

@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    height: 100%;
  }
}

img.crop-image {
  width: 50vw;
  height: auto;
}

@media (max-width: 768px) {
  img.crop-image {
    width: 80vw;
  }
}

.modal-cropper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.qualityMessage {
  display: flex;
  position: absolute;
  z-index: 1000000;
  top: 60px;
  left: 0;
  padding: 5px 5px 10px 10px;
  background: #ed970e;
  width: 100%;
  height: 42px;
  color: #fae9ce;
  font-size: 15px;
  text-align: center;
  line-height: 28px;
}
button.close {
   color: #fff;
  padding-left: 2px;
  font-size: 18px;
  text-decoration: underline;
  letter-spacing: 1px;
  
}
.close-button {
  float: right;
  margin-right: 10px;
  font-size: 30px;
  font-weight: 600;
  color: #8e5a06;
  margin-top: 1px;
  &:hover {
    cursor: pointer;
    color: #fff;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
  }
  }