$primary-color: #f0a;
$primary-color-hover: #af2e81;
$secondary-color: #ff8172;
$secondary-color-hover: #de6354;
$tertiary-color: #18b2bb;
$tertiary-color-hover: #266d71;
$third-color: #fff;

$menu-background: #fcf4ed;
$screen-mi: 480px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-nm: 960px;
$screen-lg: 992px;
$screen-xl: 1200px;
