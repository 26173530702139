//
// Colors
//
.u-color1 {
  color: $tertiary-color;
}
.u-color2 {
  color: $secondary-color;
}
.u-color3 {
  color: $third-color;
}
//
// Screen readers only
//
.u-sr-only {
  // Screen reader content: http://a11yproject.com/posts/how-to-hide-content//
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
//
// Alignment
//
.u-alignright {
  text-align: right;
}
.u-alignleft {
  text-align: left;
}
.u-pointer {
  cursor: pointer;
  border: 0px;
  background: none;
  color: #fae9ce;
  font-family: inherit;
  text-decoration: underline;
}
.u-pointerPure {
  cursor: pointer;
  // don't add more css here, this is a utility just for change the coursor to pointer
}
.u-responsive-image {
  display: block;
  max-width: 100%;
}
// Utilities for sizing (margin and paddings)
.u-m0 {
  margin: 0 !important;
}
.u-p0 {
  padding: 0 !important;
}
// Text
.u-uppercase {
  text-transform: uppercase;
}
// Text
.u-center {
  margin-left: auto;
  margin-right: auto;
}

/* Padding Classes */
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.25rem;
} /* 4px */
.p-2 {
  padding: 0.5rem;
} /* 8px */
.p-3 {
  padding: 1rem;
} /* 16px */
.p-4 {
  padding: 1.5rem;
} /* 24px */
.p-5 {
  padding: 2rem;
} /* 32px */

/* Individual Side Padding Classes */
.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 1rem;
}
.pt-4 {
  padding-top: 1.5rem;
}
.pt-5 {
  padding-top: 2rem;
}

.pr-0 {
  padding-right: 0;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 1rem;
}
.pr-4 {
  padding-right: 1.5rem;
}
.pr-5 {
  padding-right: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 1rem;
}
.pb-4 {
  padding-bottom: 1.5rem;
}
.pb-5 {
  padding-bottom: 2rem;
}

.pl-0 {
  padding-left: 0;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 1rem;
}
.pl-4 {
  padding-left: 1.5rem;
}
.pl-5 {
  padding-left: 2rem;
}

/* Margin Classes */
.m-0 {
  margin: 0;
}
.m-1 {
  margin: 0.25rem;
} /* 4px */
.m-2 {
  margin: 0.5rem;
} /* 8px */
.m-3 {
  margin: 1rem;
} /* 16px */
.m-4 {
  margin: 1.5rem;
} /* 24px */
.m-5 {
  margin: 2rem;
} /* 32px */

/* Individual Side Margin Classes */
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 1rem;
}
.mt-4 {
  margin-top: 1.5rem;
}
.mt-5 {
  margin-top: 2rem;
}

.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 1rem;
}
.mr-4 {
  margin-right: 1.5rem;
}
.mr-5 {
  margin-right: 2rem;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 1.5rem;
}
.mb-5 {
  margin-bottom: 2rem;
}

.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 1rem;
}
.ml-4 {
  margin-left: 1.5rem;
}
.ml-5 {
  margin-left: 2rem;
}
