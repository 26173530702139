$color: #18b2bb;
.profile {
  padding-top: 80px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: 0px auto;
  align-items: center;
  gap: 20px;
  &Menu {
    display: flex;
    background-color: yellow;
    flex-direction: row;
    max-width: 480px;
    padding: 5px;
    margin: 0px auto 20px auto;
    width: 90%;
    background: rgba(0, 0, 0, 0.03);
    box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.1);
    // border:1px solid #fff;
    border-radius: 8px;
    .segment {
      display: block;
      cursor: pointer;
      width: 100%;
      font-size: 14px;
      text-align: center;
      line-height: 36px;
      border: 0px;
      background-color: transparent;
      text-decoration: none;
      .normal {
        background-color: rgba(#fff, 0);
        color: rgba(0, 0, 0, 0.5);
        transition: 0.3s all ease-in-out;
        text-decoration: none;
        &:hover {
          text-decoration: none;
          color: $color;
        }
      }
      .active {
        background-color: #fff;
        color: #18b2bb;
        border-radius: 8px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
      }
    }
  }
  .profileAddress {
    background: #c3f3e3;
    border-radius: 8px;
    padding: 10px 20px;
    line-height: 24px;
  }
}
.details {
  display: flex;
  flex-direction: column;
  // max-width: 320px;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin: 0px auto;
  &.center {
    text-align: center;
    h2 {
      text-align: center;
    }
  }
  .detailsRow {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  .detailsSection {
    position: relative;
    width: 100%;
    max-width: 600px;
    padding: 16px;
    .savingDataAnimation {
      position: absolute;
      top: 15px;
      right: 30px;
      width: 36px;
      height: 36px;
    }
    .buttonContainer {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  h2,
  h4 {
    width: 100%;
    max-width: 320px;
    align-items: center;

    .ion-icon,
    ion-icon {
      margin-right: 10px;
      font-size: 16px;
      line-height: 20px;
      color: #fff;
      background: #18b2bb;
      border-radius: 30px;
      padding: 5px;
    }
    &.red {
      color: #ed7b6d;
      .ion-icon {
        background-color: #ed7b6d;
        color: #fff;
      }
    }
  }
  h2.iconHeader {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    .ion-icon {
      width: 24px;
      height: 24px;
    }
  }

  form {
    max-width: 600px;

    fieldset {
      width: 100%;
      max-width: 600px;
      // padding: 16px;
    }
  }
  fieldset {
    margin: 0px;
    // padding-left:0px;
    label {
      // padding-left:0px;
    }
    * {
      margin-left: 0px;
    }
    width: 100%;
    max-width: 600px;
  }
}
.packages {
  display: flex;
  flex-direction: row;
  max-width: 960px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 960px) {
    flex-wrap: wrap;
  }
  .package {
    width: 100%;
    max-width: 320px;
    min-width: 300px;
    min-height: 200px;
    display: flex;
    position: relative;
    text-decoration: none;
    background: #18b2bb;
    color: #fff;
    border-radius: 3px;
    overflow: hidden;
    border-radius: 8px;
    &.color-1 {
      background: #c274c3;
      .banner {
        color: #c274c3;
      }
    }
    &.color-2 {
      background: #eead81;
      .banner {
        color: #eead81;
      }
    }
    @media screen and (max-width: 320px) {
      max-width: 300px;
    }
    &Content {
      padding: 25px 15px 15px;
      display: flex;
      gap: 5px;
      flex-direction: column;
      align-items: left;
      justify-content: space-between;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    &Header {
      width: 100%;
      // padding: 0px 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: left;
      gap: 10px;
    }
    .gradient {
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      opacity: 0.4;
      background: linear-gradient(288.6deg, #000000 0%, rgba(0, 0, 0, 0) 99.17%);
      mix-blend-mode: soft-light;
    }
    &Footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 180px;
      border-radius: 3px;
    }
    h4 {
      font-size: 21px;
      line-height: 24px;
      font-weight: bold;
      margin: 5px 0px 0px;
      letter-spacing: 1px;
      display: flex;
    }
    p {
      font-family: sans-serif;
      margin: 0px;
      max-width: 240px;
      line-height: 20px;
      font-size: 13px;
      letter-spacing: 1px;
      text-align: left;
    }
    .banner {
      font-family: Arial, Helvetica, sans-serif;
      position: absolute;
      color: #18b2bb;
      text-align: center;
      font-weight: bold;
      line-height: 30px;
      rotate: 39deg;
      top: 20px;
      right: -63px;
      font-size: 10px;
      width: 200px;
      z-index: 3;
      font-weight: bolder;
      background: #fcf4ed;
      text-transform: uppercase;
    }
    .priceWrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .strikethrough {
        text-decoration: line-through;
      }
      .price {
        font-size: 22px;
        font-weight: bold;
      }
    }
    .arrow {
      position: relative;
      right: 10px;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      text-decoration: none;
      .arrow {
        right: 0px;
      }
    }
  }
}
.updatePackage {
  width: 100%;
  background: #fff;
  border: 1px solid #eee;
  padding: 10px 0px;
  margin-bottom: 10px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  border-radius: 8px;
  max-width: 280px;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
  }
  h4 {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    margin: 0px;
    min-height: 50px;
    padding: 0px;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    margin: 0px;
    min-height: 60px;
    padding: 0px 10px;
  }
  .price {
    font-size: 16px;
    font-weight: bold;
    color: #18b2bb;
  }
  .upsellButton {
    width: 100%;
    .button {
      line-height: 20px;
      max-width: 90%;
      font-size: 13px;
    }
  }
}
.membershipOverview {
  h2,
  p {
    text-align: center;
  }
  p {
    margin: 0px;
  }
}

.deleteAccount {
  .deleteLink {
    text-decoration: underline;
    text-transform: none;
    color: #ff8172;
    border: 0px;
    background: none;
    text-align: center;
    line-height: 38px;
    width: 100%;
  }
  .deleteInput {
    text-transform: uppercase;
    // color: #ff8172;
    letter-spacing: 2px;
  }
}
