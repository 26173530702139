section {
  &.submit-slide {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    .flex-wrapper {
      .flex-container {
        margin-top: 40px;
        position: relative;
        background-color: #fef2d1;
        padding: 20px;
        z-index: 4;
        .Arrow {
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 10px;
        }
        > * {
          z-index: 4;
        }
        &::after {
          content: '';
          position: absolute;
          z-index: 1;
          // We can shrink the pseudo element here to hide the shadow edges
          left: -2px;
          top: -2px;
          right: -2px;
          bottom: -2px;
          border: 15px dotted #fff;
        }
      }
      @media (max-width: 480px) {
        .flex-container {
          margin-top: 20px;
        }
      }
    }
  }
}
.uploadFooter {
  position: fixed;
  bottom: 0px;
  display: flex;
  gap: 10px;
  padding: 10px 0px;
  width: 100%;
  margin: 0px auto;
  min-height: 48px;
  background: rgba(#fff, 0.9);
  z-index: 290;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(10px);
  border-top: 1px solid #fff;
  p,
  .button {
    margin: 0px;
  }
  button {
    padding: 5px;
    // height:36px;
  }
}
