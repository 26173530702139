.cancelOffersTitle {
  align-items: center;
  max-width: 480px;

  h2 {
    text-align: center;
    white-space: pre-line;
    width: 100%;
    display: block;
    max-width: 100%;
  }
  p {
    text-align: center;
  }
}

.cancelOffersContainer {
  display: inline-flex;
  //   gap: 20px;
  //   flex: 1px;
  //   min-height: 400px;
  gap: 20px;
  padding: 10px;
  @media (max-width: 768px) {
    max-width: 300px;
    flex-direction: column;
  }
}

.cancelOfferComingSoonContainer {
  display: inline;
  gap: 20px;
  max-width: 420px;
  flex: 1;
  background: #c3f3e3;
  padding: 10px;
  color: #333;
  border-radius: 8px;

  h2 {
    text-align: center;
    max-width: 100%;
    font-weight: bold;
  }

  p {
    text-align: center;
  }
}

.cancelReasonsContainer {
  .selectionOption {
    padding: 10px;

    input {
      display: -webkit-inline-flex;
    }

    label {
      display: -webkit-inline-flex;
      padding-left: 8px;
    }
  }
}
