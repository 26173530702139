input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea {
  border: 1px solid #ddd;
  box-shadow:
    inset 0 0 0 1px rgba(255, 255, 255, 0),
    inset 0 0 0 100px rgba(255, 255, 255, 1);

  border-radius: 5px;
  margin: 10px 0 0 0;
  width: 100%;
  line-height: 36px;
  font-size: 16px;
  padding: 4px 10px;
  display: inline-block;
  transition: all 0.15s ease-in-out;
  outline: 0;
  -webkit-appearance: none;

  &:focus {
    border: 1px solid #eee;
    outline: #18b2bb auto 1px; // outline: 0;
  }

  @media screen and (max-width: 1280px) {
    width: 100%;
    text-align: left;
  }
}

input[type='file'] {
  width: 100px;
}

textarea {
  margin-bottom: 10px;
  min-height: 280px;
}

label {
  display: block;
  text-align: left;
}
.fieldsetRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
fieldset {
  border: 0;
  clear: both;
  margin-bottom: 0;
  max-width: 280px;
  padding: 6px 0px;
  margin: 0px auto;
  &.half {
    width: 50%;
    &:last-child {
      padding-left: 0px;
    }
  }
  label {
    float: left;
    padding-left: 5px;
  }

  &.radio {
    padding: 0 !important;

    label {
      float: none;
      display: inline-block;
      line-height: 60px;
      width: 100%;

      &.multiline {
        line-height: 1.6em;
        margin-bottom: 20px;
        padding: 0;
      }
    }

    input {
      position: relative;
      top: -1px;
      margin-right: 5px;
      margin-left: 10px;
      outline: 0;
    }

    #toFriend {
      margin-left: 30px;
    }

    .input-wrapper {
      padding: 12px;
      background: #f7f7f7;
      border-radius: 3px;

      label {
        line-height: initial;
        width: auto;
      }
    }
  }

  legend {
    float: right;
    color: #999;
    padding-right: 5px;
    font-size: 11px;
    font-family: sans-serif;
  }

  .input-wrapper {
    position: relative;

    .icon,
    .done {
      position: absolute;
      top: 40px;
      right: 15px;
      z-index: 10;
      font-size: 16px;
      color: #999;
      display: none;
    }

    .done {
      color: #18b2bb;
    }
  }

  &.small {
    display: inline-block;
    float: left;
    width: calc(50% - 8px);
    margin-right: 5px;
    clear: none;

    .message {
      font-size: 10px;
    }
  }

  small {
    font-size: 11px;
    padding: 10px;
    line-height: 16px;
    color: #f0a;
    display: inline-block;
  }

  .message {
    text-align: left;
    padding-left: 5px;
    line-height: 24px;
    color: #f0a;
    border-top: 2px solid #f0a;
    font-size: 11px;
    display: none;
    font-family: sans-serif;
  }

  .notify {
    font-size: 12px;
    text-align: center;
  }

  select {
    display: inline-block;
    width: 100%;
    height: 46px;
    margin-top: 10px;
    background: none;
    border: 1px solid #eee;
    background: #fff;
    padding-left: 5px;
    outline: 0;
    font-size: 16px;
  }
}

.DisplayWarning {
  margin: 20px;
  padding: 20px;
  font-size: 16px;
  border-radius: 5px;
  color: red;
  border: 1px solid red;
}
