.checkOut {
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 360px;
  margin: 100px auto 0px auto;
  text-align: center;
  padding: 15px 15px;
  gap: 10px;
  border-radius: 8px;
  h2,
  p {
    max-width: 360px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  p {
    margin: 0px;
    font-size: 14px;
  }
  h2.green {
    width: 100%;
    color: #333;
    text-transform: uppercase;
    font-size: 12px;
  }
  .titleHeader {
    gap: 0px;
    width: 100%;
    // background: #fcf4ed;
    padding: 5px;
    border-radius: 3px;
  }
  img {
    width: 100%;
    border-radius: 3px;
    max-height: 200px;
    object-fit: cover;
  }
  small {
    margin-top: 20px;
    color: #666;
    width: 70%;
  }
  @media (max-width: 480px) {
    margin: 100px 15px;
    padding: 10px 10px 30px 10px;
    gap: 10px;
    small {
      width: 90%;
    }
  }
  .gifAnimation {
    border-radius: 300px;
    width: 160px;
    height: 160px;
    object-fit: cover;
    border: 4px solid rgba(255, 255, 255, 0.7);
  }
  .buttonHolder {
    width: 100%;
  }
}
.checkoutMessage {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.successAddress {
  background-color: #fcf4ed;
  color: #333;
  width: 280px;
  border-radius: 10px;
  margin: 0px auto;
  font-size: 12px;
  text-align: left;
  padding: 15px 10px 15px 50px;
  p {
    width: auto;
    line-height: 18px;
  }
}
.fauxButton {
  text-decoration: none;
  position: relative;
  background-color: #ccc;
  color: #fff;
  display: flex;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
  left: 0;
  line-height: 30px;
  text-align: right;
  font-weight: 400;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  max-width: 300px;
  padding: 10px 20px;
  text-transform: uppercase;
  margin-top: 20px;
  align-items: center;
  justify-content: left;
  span {
    width: 100%;
    text-align: center;
  }
  .savingDataAnimation {
    position: absolute;
    left: 10px;
    transform: none;
    padding: 0px;
    margin: 0px;
    height: 30px !important;
    width: 30px !important;
    svg {
      path[fill='rgb(0,0,0)'] {
        fill: white;
      }
      path[stroke='rgb(0,0,0)'] {
        stroke: white;
      }
    }
  }
}
