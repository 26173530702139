.pauseContainer {
  justify-content: center;
  align-items: center;
  background-color: #fcf4ed;
  width: 350px;
  padding: 20px;
  flex-direction: column;
  display: flex;
  border-radius: 8px;

  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  .selectionOption {
    padding: 10px;

    input {
      display: -webkit-inline-flex;
    }

    label {
      display: -webkit-inline-flex;
      padding-left: 8px;
    }
  }
}
