.MembershipCardContainer {
  max-width: 360px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fcf4ed;
  border-radius: 8px;

  .titleContainer {
    background-color: #18b2bb;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 8px;
  }

  h2 {
    color: white;
    margin: 0px;
    text-align: center;
    padding: 8px;
  }

  .labelItem {
    width: 100%;
    display: inline-flex;
  }

  .labelTitle {
    margin: 0px;
    font-weight: bold;
    color: #333333;
    padding: 4px;
  }

  .labelValue {
    margin: 0px;
    color: #333333;
    padding: 4px;
  }
}
