.button {
  text-decoration: none;
  position: relative;
  background-color: $tertiary-color;
  color: #fff;
  display: inline-block; //   text-transform: uppercase;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
  left: 0;
  line-height: 30px;
  text-align: center;
  font-weight: 400;
  letter-spacing: 1px;
  font-weight: bold;

  font-size: 14px;
  width: 100%;
  max-width: 280px;
  padding: 10px 20px;
  padding-bottom: 7px;
  text-transform: uppercase;
  margin-top: 20px;
  font-family: 'Josefin Sans', sans-serif;
  &.fixed {
    position: fixed;
    bottom: 20px;
    left: 50%;
    max-width: 280px;
    letter-spacing: 1px;
    line-height: 22px;
    transform: translateX(-50%);
    z-index: 500;
  }
  &:hover {
    text-decoration: none;
    background-color: $tertiary-color-hover;
  }
  &.mini {
    font-size: 12px;
    padding: 0;
    min-width: 80px;
    margin: 10px;
    height: 30px;
    line-height: 20px;
  }
  &.small {
    font-size: 16px;
    line-height: 39px;
    padding: 2px 22px;
    border-radius: 10px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  &.wide {
    width: 100%;
    padding: 15px 20px;
  }
  &.disabled,
  &:disabled {
    background-color: #999;
    color: #fff;
  }
  &.apple {
    background:
      url('../img/appstore/apple.svg') no-repeat 7% 45%,
      #333;
    text-align: left;
    padding: 10px 20px 7px 60px;
    color: #fff;
    max-width: 280px;
    font-size: 12px;
    text-transform: uppercase;
    margin: 10px auto 0px;
    letter-spacing: 1px;
  }
  &.google {
    background:
      url('../img/appstore/google.svg') no-repeat 7% 45%,
      #333;
    text-align: left;
    padding: 10px 20px 7px 60px;
    color: #fff;
    max-width: 280px;
    font-size: 12px;
    text-transform: uppercase;
    margin: 10px auto 0px;
    letter-spacing: 1px;
  }
  &.lined {
    background: #fff;
    border: 1px solid #333;
    color: #333;
    padding: 5px 20px 3px 30px;
    &.google {
      font-size: 14px;
      background:
        url('../img/appstore/google.svg') no-repeat 7% 45%,
        #fff;
      text-align: center;
      margin-bottom: 10px;
    }
    &.apple {
      font-size: 14px;
      margin: 0px;

      text-align: center;
      background:
        url('../img/appstore/apple-black.svg') no-repeat 7% 45%,
        #fff;
    }
  }
  &.klikkie {
    background:
      url('../img/appstore/klikkie.svg') no-repeat 7% 45%,
      #333;
    text-align: left;
    padding: 10px 20px 10px 60px;
    color: #fff;
    max-width: 280px;
    font-size: 12px;
    text-transform: uppercase;
    margin: 10px auto 0px;
    letter-spacing: 1px;
  }
  &.whatsapp {
    background:
      url('../img/appstore/whatsapp.svg') no-repeat 7% 45%,
      #25d366;
    background-size: 24px 24px;
    text-align: left;
    padding: 10px 20px 10px 60px;
    color: #fff;
    max-width: 280px;
    font-size: 12px;
    text-transform: uppercase;
    margin: 10px auto 0px;
    letter-spacing: 1px;
  }
  &.copy {
    background:
      url('../img/appstore/copy.svg') no-repeat 7% 45%,
      #18b2bb;
    background-size: 24px 24px;
    text-align: left;
    padding: 10px 20px 10px 60px;
    color: #fff;
    max-width: 280px;
    font-size: 12px;
    text-transform: uppercase;
    margin: 10px auto 0px;
    letter-spacing: 1px;
  }
}
input[type='submit'] {
  cursor: pointer;
}
