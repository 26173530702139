.addressBookSelector {
  padding-top: 10px !important;
  padding: none !important;
  height: auto !important;

  .bookSelector {
    width: 100%;

    .radioBoxWrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 0 0 calc(33.3% - 10px);
      margin-top: 10px;

      @media screen and (max-width: 480px) {
        flex: 0 0 calc(50% - 10px);
      }

      input {
        position: absolute;
        left: 10px;
        border: 0px;
        outline: 0;

        &:checked + label {
          background: #c3f3e3;
        }

        &:checked + .price {
          opacity: 1;
        }
      }
    }
  }
}

.addressBook {
  height: 100vh;
  max-width: 600px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 70px 10px 0px;
  width: 100%;
  text-align: center;
  align-items: center;

  label {
    text-transform: capitalize;
  }

  .googlePlacesInput {
    width: 100%;
    max-width: 400px;
    margin: 0px auto;
    margin-bottom: 20px;
  }

  .addExtraLine {
    text-align: end;
    text-decoration: underline;
    font-size: 1em;
    margin: 0px;
    color: #555555;
    cursor: pointer;
  }

  .form {
    width: 100%;
    max-width: 400px;
  }

  h2 {
    text-align: center;
  }

  .addAddressBtn {
    max-width: calc(55% - 10px) !important;
  }

  .highlightError {
    border: 1px solid #eee;
    outline: #ed7b6d auto 1px;
  }

  .addAddress {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    width: 100%;
    background: #c3f3e3;
    height: 48px;
    border-radius: 8px;
    color: #333;
    text-decoration: none;
    flex-shrink: 0;
    max-width: calc(33.3% - 10px);

    .circle {
      background: #fff;
      border-radius: 100%;
      height: 24px;
      width: 24px;
      margin: 0px 10px 0px;
      line-height: 24px;
      text-align: center;
      justify-content: center;
      display: flex;
      font-weight: bold;
      font-size: 14px;
      color: #555;
    }

    @media screen and (max-width: 480px) {
      max-width: calc(50% - 10px);
    }
  }

  .book {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    width: 100%;

    //checkbox
    .checkBoxWrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 0 0 calc(33.3% - 10px);

      @media screen and (max-width: 480px) {
        flex: 0 0 calc(50% - 10px);
      }

      input {
        position: absolute;
        left: 10px;
        border: 0px;
        outline: 0;

        &:checked + label {
          background: #c3f3e3;
        }

        &:checked + .price {
          opacity: 1;
        }
      }
    }
  }

  .addressValidationWrapper {
    color: red;
    position: fixed;
    bottom: 70px;
  }
}

.AddressBookItem {
  position: relative;
  flex: 0 0 calc(50% - 10px);

  @media screen and (max-width: 480px) {
    flex: 0 0 calc(100% - 10px);
  }

  input {
    position: absolute;
    left: 10px;
    border: 0px;
    outline: 0;

    &:checked + label {
      background: #c3f3e3;
    }

    &:checked + .price {
      opacity: 1;
    }
  }

  &Delete {
    position: absolute;
    top: 0px;
    right: 0px;

    display: inline-block;
    padding: 10px;
    border-radius: 0px 8px;
    font-size: 20px;
    color: #333;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: #fff;
      background: #ed7b6d;
    }
  }
}

.addressCheckBox {
  left: 0px;
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  background: #fcf4ed;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;

  justify-items: center;
  flex-grow: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  .primaryAddress {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .makePrimaryAddress {
    position: relative;
    bottom: 0px;
    left: 0px;
    width: 100%;
    color: #333;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    border-radius: 0px 0px 8px 8px;

    span {
      line-height: normal;
      font-size: 16px;
    }

    p {
      margin: 0px;
      line-height: 30px;
    }
  }

  &:hover {
    background: #c3f3e3;
  }

  .ion-icon {
    font-size: 24px;
  }

  &.true {
    background: #c3f3e3;

    .price {
      opacity: 1;
    }
  }

  .price {
    opacity: 0;
    text-align: right;
    color: #18b2bb;
  }

  .addressDetails {
    width: calc(100% - 20px);
    margin-left: 20px;

    div {
      margin: 3px;
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
}
