.preview {
  position: relative;
}

.temp,
.retryContainer {
  position: absolute;
  width: 100%;
}
.thumbnail {
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  .bg {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 251px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;

    &:hover,
    &:focus {
      box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 30px;
      z-index: 2;
      transition: all 0.1s ease-in-out;
    }

    .picture-wrapper {
      background-color: #c3f3e3;
      background-repeat: no-repeat;
      background-size: 145px 145px;
      background-position: center center;
      image-rendering: -webkit-optimize-contrast;
      object-fit: cover;
    }

    .loading-container {
      background-color: #18b2bb;

      height: 2px;
      position: absolute;
      bottom: -1px;
      left: 0px;

      transition: width 1s linear;
    }

    .processing {
      animation: colorchange 2s linear infinite;
      transition: width 1s ease-in;
      animation-delay: 1s;
    }

    @keyframes colorchange {
      0% {
        background-color: rgb(19, 179, 53);
      }
      50% {
        background-color: rgb(32, 207, 16);
      }
      100% {
        background-color: rgb(19, 179, 53);
      }
    }
  }
}

.thumbnail {
  // max-width: 640px;
  max-height: auto;
  box-shadow: none;
  // zoom:0.5;
  @media (max-width: 480px) {
    min-height: auto;
  }

  .bg {
    border: 0;

    @media (max-width: 480px) {
      min-height: auto;
    }
  }
}

.pictureShadow {
  position: relative;
  -webkit-box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;

  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    top: 10px;
    bottom: 10px;
    left: 0;
    right: 0;
    -moz-border-radius: 100px / 10px;
    border-radius: 100px / 10px;
  }

  &:after {
    right: 10px;
    left: auto;
    -webkit-transform: skew(8deg) rotate(3deg);
    -moz-transform: skew(8deg) rotate(3deg);
    -ms-transform: skew(8deg) rotate(3deg);
    -o-transform: skew(8deg) rotate(3deg);
    transform: skew(8deg) rotate(3deg);
  }
}
.uploadMessage {
  position: absolute;
  align-items: center;
  width: 100%;
  max-width: 180px;
  gap: 2px;
  display: flex;
  flex-direction: column;
  img {
    height: 54px;
  }
  p {
    font-size: 1em;
    margin: 0px;
  }
}
