html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
.product-package {
  background: #fcf4ed;
  max-width: 800px;
  position: relative;
  // padding-top: 20px;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  padding-bottom: 40px;
  margin: 0px auto;
  @media screen and (max-width: 640px) {
    border-radius: 0px;
    width: 100%;
    .introMonthTitle {
      border-radius: 0px;
    }
  }
}
.introMonthTitle {
  h1 {
    text-transform: uppercase;
    font-size: 2em;
    color: rgba(0, 0, 0, 0.6);
    // letter-spacing: 4px;
    padding: 12px;
    font-weight: 400;
  }
  // border-bottom: 1px solid #fff;
  overflow: hidden;
  margin: 0px auto;
  text-align: center;
  border-radius: 8px 8px 0px 0px;
  position: relative;
  background: rgba(0, 0, 0, 0.1);
}
.photoSet {
  // background: rgba(0, 0, 0, 0.1);
  // padding:10px 0px;
  padding-bottom: 10px;
  position: relative;
  p {
    margin: 0px;
  }
}
.set-select-Modal {
  padding: 0px;
  background: #fcf4ed;
}
.set-select {
  display: block;
  height: 36px;
  min-width: 240px;
  background: #fff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 12px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: #333;
  margin: 10px auto 20px;
  padding: 0 20px 0px 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    border-color: #18b2bb;
  }
  &:focus {
    outline: 0 !important;
  }
  .icon {
    // float: right;
  }
}
.select-set {
  position: absolute;
  padding: 20px 0px 40px 0px;
  top: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #fcf4ed;
  text-align: center;
  box-shadow: none;
  left: 50%;
  transform: translateX(-50%);
  h2 {
    &::first-letter {
      text-transform: capitalize;
    }
  }
}
.photo-set-card {
  margin: 0px auto;
  display: inline-block;
  width: 320px;
  left: 32px;
  top: 75px;
  cursor: pointer;
  background: #fff;
  margin-top: 15px;
  padding: 10px;
  filter: drop-shadow(0px 5px 10px rgba(101, 101, 101, 0.05));
  &:hover {
    outline-color: #18b2bb;
    outline-style: solid;
    outline-width: thin;
  }
  &:hover .selector-container .selector {
    color: #c9c9c9;
  }
  .selector-container {
    width: 30px;
    .selector {
      position: absolute;
      width: 30px;
      height: 30px;
      left: 9px;
      top: 11px;
      line-height: 32px;
      border-radius: 30px;
      border: 0;
      /* Light/Background Soft */
      color: rgba(0, 0, 0, 0);
      background: #f5f3f2;
      &.selected {
        background-color: #18b2bb;
      }
      .selected {
        color: #fff;
      }
    }
  }
  h3 {
    /* Header */
    margin-left: 46px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    margin-bottom: 0;
    margin-block-start: 0;
    /* Light/Green */
    color: #18b2bb;
  }
  .card-description {
    /* Label */
    margin-left: 46px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    letter-spacing: 1px;
    /* Light/Text Muted */
    color: #555555;
  }
  .card-image {
    width: 299px;
    height: 92px;
    left: 13px;
    top: 88px;
    background: #f5f3f2;
    border-radius: 3px;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
}
.qualityModal {
  h3 {
    margin: 22px 0px 10px;
    text-align: left;
    padding: 0px;
    font-size: 1.4em;
    letter-spacing: 2px;
  }
}
.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  .preview {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .canvas-container {
    // background: #eee;
    // border: 1px solid #eee;
    left: 50%;
    transform: translateX(-50%);
  }
  #file {
    display: none;
  }
}
.mini-view {
  margin: 0 auto;
  align-items: center;
  justify-content: space-around; // width: 70%;
  height: auto; // padding: 4%;
  position: relative;
  .slide {
    min-width: 30%;
    padding-right: 10px;
    padding-left: 10px;
    max-height: 360px;
    .preview,
    .thumbnail {
      width: 320px !important;
      height: 320px !important;
      .bg .picture-wrapper {
        width: 320px !important;
        height: 320px !important;
      }
    }
  }
}
.medium-view {
  .slide .thumbnail {
    .bg {
      .landscape {
        width: 80vw;
        height: calc(80vw * 0.678);
        max-width: 640px;
        max-height: calc(640px * 0.678);
      }
      .portrait {
        width: calc(80vw * 0.678);
        height: 80vw;
        max-width: calc(640px * 0.678);
        max-height: 640px;
      }
    }
  }
}
.xl-view {
  .slide .thumbnail .bg {
    .landscape {
      width: 80vw;
      height: calc(80vw * 0.75);
      max-width: 640px;
      max-height: calc(640px * 0.75);
    }
    .portrait {
      width: calc(80vw * 0.75);
      height: 80vw;
      max-width: calc(640px * 0.75);
      max-height: 640px;
    }
  }
}
.strip-view {
  position: relative;
  display: inline-block; // transform-origin: top center;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding-bottom: 34px;
  .slide {
    margin-bottom: -10px;
    box-shadow: none;
    .preview,
    .thumbnail {
      width: 251px !important;
      height: 251px !important;
      max-width: 251px;
      .bg .picture-wrapper {
        width: 251px !important;
        height: 251px !important;
        box-shadow: none;
      }
      &::before,
      &::after {
        box-shadow: none;
      }
    }
  }
}
.relativeSlider {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 670px;
  width: 80%;
  margin: 0 auto;
  padding: 40px;
  background: #8ed7f0;
  .slide {
    width: 800px;
    padding: 100px;
  }
}
canvas {
  touch-action: none;
}
// Demonstrate a "mostly customized" scrollbar
// (won't be visible otherwise if width/height is specified) */
.relativeSlider::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  // /* or add it to the track */
}
// /* Add a thumb */
.relativeSlider::-webkit-scrollbar-thumb {
  background: #fff;
}
