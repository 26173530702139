.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  justify-content: center;
  z-index: 400;
  align-items: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  display: flex;
  .animation {
    width: 60px;
  }
  &.invert {
    filter: invert(100%);
  }
  .logo {
    margin-top: 5px;
    width: 80px;
  }
  &.constrained {
    min-height: 400px;
    position: static;
    background-color: white;
  }
}
$animationColor: #000;
.savingDataAnimation {
  transform: translateX(50%);
  // box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
  // border:1px solid #fff;
  // border-bottom: 0px;
  border-radius: 100px;
  padding: 5px;
  z-index: 1000;
  width: 42px;
  height: 42px;
  margin: 0px auto;
  svg {
    path[fill='rgb(0,0,0)'] {
      fill: $animationColor;
    }
    path[stroke='rgb(0,0,0)'] {
      stroke: $animationColor;
    }
  }
  @media (max-width: 640px) {
    right: 10px;
  }
}
