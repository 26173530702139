.product-line-item {
  background: #f9f9f9;
  border: 1px solid #eaeaea;
  padding: 15px;
  display: flex;
  gap: 20px;
  flex-direction: row;

  .product-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .product-name {
      font-size: 16px;
      padding-bottom: 6px;
      font-weight: bold;
    }
    .product-variant {
      font-size: 14px;
      flex: 1;
    }
    .product-status {
      color: #18b2bb;
      padding-top: 10px;
    }
  }

  .product-image {
    .product-image-item {
      width: 90px;

      aspect-ratio: 1;
      object-fit: cover;
    }
  }

  .product-price {
    margin-left: auto;
    .price {
      margin-top: 0px;
    }
  }
}
