.loading-container {
  height: 100%;
  align-self: center;

  p {
    text-transform: uppercase;
  }
}

.checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 0px auto;
  padding-top: 80px;

  .title {
  }

  .checkout-container-inner {
    position: relative;
    width: 100%;
    max-width: 600px;
    padding: 16px;

    display: inline-flex;
    flex-direction: column;

    gap: 20px;

    .checkout-line-item {
      background: #f9f9f9;
      border: 1px solid #eaeaea;
      padding: 15px;
      display: flex;
      gap: 20px;
      flex-direction: row;
      align-items: flex-start;

      .title {
        width: 100%;
        text-align: left;
        margin: 0px;
      }

      .descriptor {
        margin: 0px;
      }
    }

    .total-line-item {
      padding: 15px;
      display: flex;
      gap: 20px;
      flex-direction: row;
      align-items: flex-start;

      .title {
        width: 100%;
        text-align: left;
        margin: 0px;
      }

      .descriptor {
        margin: 0px;
      }
    }
  }
}
