.NotSupported {
  // border: 1px solid blue;

  &__title {
    // border: 1px solid red;
    margin-top: 82px;
  }

  &__content {
    // border: 1px solid green;
  }
}
