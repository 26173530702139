.offerCardContainer {
  align-items: center;
  min-width: 300px;
  border-color: #eee;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background-color: white;
  height: 100%;
  margin: auto;

  .topImage {
    height: 150px;
    width: 100%;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  h2 {
    text-align: center;
    display: inline-table;
    margin: 5px 0px;
  }

  .descriptionContainer {
    flex: 1;
    padding: 8px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  p {
    text-align: center;
  }

  .priceContainer {
    text-align: center;

    .priceLabel {
      display: inline-flex;
    }

    h2 {
      color: #18b2bb;
      font-weight: bold;
    }
  }
}
