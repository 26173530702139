.modal-alert {
  position: fixed;
  width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  border-radius: 15px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalContent p {
  margin-bottom: 20px;
  font-size: 16px;
}

.btnCancel {
  background: none;
  color: #999;
  text-decoration: underline;
}

button.button {
  margin: 10px;
  padding: 10px;
  font-size: 12px;
  cursor: pointer;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 800px) {
  .modal-alert {
    width: 90%;
  }
}
