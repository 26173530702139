.orderHistory {
  padding-top: 40px;

  .pointer {
    cursor: pointer;
  }

  .order-list {
    list-style-type: none;
    padding: 0;
    width: 100%;
    li {
      margin-top: 16px;
    }
    .order-item {
      padding: 0 10px;

      display: flex;
      flex-direction: column;

      .order-header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: stretch;
      }

      .order-date {
        margin-bottom: 5px;
      }

      a {
        color: #000 !important;
      }

      .order-detail {
        background: #f9f9f9;
        border: 1px solid #eaeaea;
        padding: 15px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        .product-item {
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          .product-name {
            font-size: 16px;
            padding-bottom: 6px;
          }
          .product-variant {
            font-size: 14px;
            flex: 1;
          }
          .product-description {
            font-size: 14px;
            flex: 1;
          }
          .product-status {
            color: #18b2bb;
            padding-top: 10px;
          }
        }
        .product-price {
          margin-left: auto;
          .price {
            margin-top: 0px;
          }
        }

        .product-image {
          .product-image-item {
            width: 90px;

            aspect-ratio: 1;
            object-fit: cover;
          }
        }
        .product-name {
          font-weight: bold;
        }
        .product-price {
          font-weight: bold;
        }
      }
    }
  }

  .total-cost-wrapper {
    padding: 0 10px;
    width: 100%;
  }

  .shipping-wrapper {
    text-align: left;
    width: 100%;
    padding: 0 10px;

    .shipping-header {
      padding: 10px 0;
    }

    .address-box {
      background-color: #c3f3e3;
      border-radius: 6px;
      width: 100%;
      text-align: left;
      padding: 15px 15px 15px 15px;
    }
  }
  .order-details {
    .order-header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: stretch;
      width: 100%;
      padding: 12px;
    }

    .order-line-items {
      width: 100%;
      .line-item {
        display: flex;
        text-align: start;
        margin-bottom: 10px;
        background: #f9f9f9;
        border: 1px solid #eaeaea;
        padding: 15px;
        .product-item {
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          .product-name {
            font-size: 16px;
            padding-bottom: 6px;
            font-weight: bold;
          }
          .product-variant {
            font-size: 14px;
            flex: 1;
          }
          .product-status {
            color: #18b2bb;
            padding-top: 10px;
          }
        }
        .product-price {
          margin-left: auto;
          .price {
            margin-top: 0px;
          }
        }

        .product-image {
          .product-image-item {
            width: 90px;

            aspect-ratio: 1;
            object-fit: cover;
          }
        }
        .product-price {
          font-weight: bold;
        }
      }
    }

    .shipping-wrapper {
      width: 100%;
      padding: 0px;
    }
    .shipping-cost-row {
      background: #f9f9f9;
      border: 1px solid #eaeaea;
      padding: 16px;
      margin-top: 5px;
      width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      align-content: stretch;
    }

    .total-cost-row {
      padding: 16px;
      margin-top: 5px;
      width: 100%;
      padding: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      align-content: stretch;

      .total {
        font-weight: bold;
      }
      h2 {
        font-weight: bold;
        margin: 0px;
      }
    }
  }
}
