$responsive-breakpoint: 606px;
$mobile-base-size: 52px;
$no-mobile-base-size: 60px;
.loading-overlay {
  position: fixed;
  left: 0;
  top: 60px;
  background-color: #1a1d23;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 72px;
    height: 72px;
  }
}
.editor-wrapper {
  z-index: 300;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: #1a1d23;
  .button {
    font-size: 12px;
    @media (min-width: $responsive-breakpoint) {
      font-size: 16px;
    }
    &.filterActive {
      color: #56c0f1;
    }
  }
  .qualityMessage {
    position: absolute;
    z-index: 3;
    top: 60px;
    left: 0;
    padding: 10px 5px 10px 10px;
    background: #ed970e;
    width: 100%;
    //height: 48px;
    color: #fae9ce;
    // font-size: 18px;
    text-align: center;
    line-height: 28px;
    @media (max-width: $responsive-breakpoint) {
      font-size: 13px;
      line-height: 18px;
    }
    button.close {
      // color: #fff;
      padding-left: 2px;
      font-size: 18px;
      text-decoration: underline;
      letter-spacing: 1px;
      @media (max-width: $responsive-breakpoint) {
        font-size: 13px;
      }
    }
  }
  .close-button {
    float: right;
    margin-right: 10px;
    font-size: 30px;
    font-weight: 600;
    color: #8e5a06;
    margin-top: 1px;
    &:hover {
      cursor: pointer;
      color: #fff;
      -webkit-user-select: none; /* Chrome all / Safari all */
      -moz-user-select: none; /* Firefox all */
      -ms-user-select: none; /* IE 10+ */
      user-select: none; /* Likely future */
    }
    @media (max-width: $responsive-breakpoint) {
      font-size: 25px;
    }
  }
  .editor-content {
    height: calc(100% - 60px);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    touch-action: manipulation;
    @media (max-width: $responsive-breakpoint) {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }
    .photoContainer {
      cursor: pointer;
      position: relative;
      background-color: #eee;
      .uploadLogoImage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        img {
          object-fit: cover;
          width: 100%;
          max-width: 56px;
        }
        p {
          margin: 0px;
        }
      }
    }
    .close {
      position: fixed;
      top: 0;
      right: 10px;
      background: none;
      border: 0;
      font-size: 48px;
      outline: 0;
    }
    .top {
      top: 0;
      bottom: auto;
    }
    .bottom {
      bottom: 0;
      top: auto;
    }
    .controls {
      height: $mobile-base-size;
      .photoTitle {
        color: #fff;
        cursor: default;
        text-transform: uppercase;

        position: absolute;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 2px;
        font-family: 'Josefin Sans', sans-serif;
        left: 50%;
        transform: translateX(-50%);
        line-height: $mobile-base-size;
        @media (min-width: $responsive-breakpoint) {
          line-height: $no-mobile-base-size;
        }
      }
      .ButtonAnnuleren__icon {
        font-size: 20px;
      }
      @media (min-width: $responsive-breakpoint) {
        height: $no-mobile-base-size;
      }
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      .button {
        background: none;
        margin-top: 0;
        font-weight: 600;
        width: auto;
        height: 100%;
        max-width: 160px;
        padding: 0 15px;
        text-transform: uppercase;
        border-radius: 0;
        line-height: $mobile-base-size;
        @media (min-width: $responsive-breakpoint) {
          line-height: $no-mobile-base-size;
        }
        & &.saveButton {
          background: #18b2bb;
          text-align: center;
          max-width: 180px;
          transition: all 0.3s ease-in-out;

          // padding:0px 10px;
        }
        &.ButtonAnnuleren {
          text-align: left;
        }
        &.inactive {
          opacity: 0.4;
        }
        @media (min-width: 1200px) {
          &:hover {
            background: #c3f3e3;
            color: #333;
          }
        }
        .icon {
          font-size: 20px;
          @media (min-width: $responsive-breakpoint) {
            font-size: 22px;
          }
          @media (min-width: 1200px) {
            &:hover {
              color: #99dff4;
            }
          }
        }
      }
      .zoom-buttons {
        display: inline-block;
        text-align: center;
        height: 100%;
        .button {
          width: 36px;
          margin: 0;
          line-height: 1;
          font-size: 22px;
          font-weight: 600;
          padding: 0;
          text-align: center;
        }
        .icon {
          font-size: 24px;
        }
      }
      .zoom-text {
        position: relative;
        top: -4px;
        color: aliceblue;
      }
      .preloader-save {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        height: 80px;
        width: 80px;
        border-radius: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 90%;
        }
      }
    }
  }
}
.ButtonAnnuleren {
  &__text {
    display: none;
    @media (min-width: 1023px) {
      display: block;
    }
  }
  &__icon {
    @media (min-width: 1023px) {
      display: none;
    }
  }
}
