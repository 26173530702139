// adds styles for the App CTA displayed on the Intro component
.containerIntro {
  background: #fff;
  margin-bottom: 0px;
  padding: 20px;
  padding-bottom: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  // border: 1px solid #eee;
  border-radius: 3px;
  font-weight: 600;

  @media (max-width: 420px) {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.2em;
    margin: 0px 0px 10px;
    letter-spacing: 1px;
    font-weight: 300;
  }
}

// adds styles for the App CTA displayed on SendSlide component
.containerSendSlide {
  // background: #fff;
  // margin-bottom: 20px;
  // padding: 20px;
  // align-items: center;
  // display: flex;
  // flex-direction: column;
  // position: relative;
  // // border: 1px solid #eee;
  // border-radius: 3px;
  // font-weight: 600;

  // .title {
  //   margin: 0px 0px 10px;
  //   font-size: 1.6em;
  //   line-height: 1.3;
  // }

  // .titleContainer {
  //   width: 70%;
  //   margin-bottom: 20px;
  // }

  // .button-icon {
  //   height: 45px;
  // }
}

// general styles for the App CTA's links/badges
.position-buttons {
  justify-content: space-between;
  flex: 1;
  height: auto;
  margin-top: 4px;
}

.button-icon {
}
