.upgradeModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f3f2;
  z-index: 20;

  .upgradeModal {
    background-color: rgba(252, 244, 237, 1);
    display: flex;
    flex-direction: row;
    width: 80%;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    height: 80vh;

    .closeBtn {
      display: flex;
      justify-content: end;
      position: absolute;
      right: 7px;
      top: 6px;
      cursor: pointer;
    }

    .leftPanel {
      // border: 1px solid red;
      flex: 1;
      background: #d9d2cd;
    }

    .canvas-frame {
      background-image: url('./images/canvas-frame.jpg');
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    .post-card {
      background-image: url('./images/postcard.jpeg');
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    .medium-frame {
      background-image: url('./images/medium.png');
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    .large-frame {
      background-image: url('./images/large.png');
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    .xlarge-frame {
      background-image: url('./images/XL.png');
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    .rightPanel {
      overflow-y: scroll;
      overflow-x: hidden;
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        color: #000;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;

        .title-light {
          color: #000;
          font-weight: 400;
        }
      }

      .sub-title {
        color: var(--Light-Header-Primary, #000);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 200;
        line-height: 170%;
        letter-spacing: 1.6px;
        padding: 10px 0 17px 0;
      }

      .continueCheckout {
        text-align: center;
        padding-top: 15px;
      }
    }

    .variants-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .variant-item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: stretch;
        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(51, 51, 51, 0.25);
        padding: 10px;
        cursor: pointer;
        max-width: 400px;
        height: 48px;
        margin: 0 auto;
        min-width: 385px;
        background: #fff;

        @media screen and (max-width: 767px) {
          min-width: 333px;
        }

        img {
          height: 25px;
          width: 25px;
          margin-right: 10px;
        }
        &.selected {
          background-color: #c3f3e3;
          border-color: #19b3bb;
        }
        .name {
          flex: auto;
          color: #555;

          &.selected {
            color: #19b3bb;
          }
        }
        .price {
          color: #555;
          &.selected {
            color: #19b3bb;
          }
        }
        .preDiscount {
          opacity: 0.5;
          text-decoration: line-through;
          margin-right: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .upgradeModal {
    flex-direction: column-reverse !important;
    width: 100% !important;
    height: 100% !important;
    overflow: auto !important;

    .leftPanel {
      background: transparent !important;
      min-height: 340px !important;

      .frame {
        background-size: contain;
        background-position: bottom left;
      }

      .post-card {
        background-size: cover !important;
        background-position: cover !important;
      }
    }

    .rightPanel {
      overflow-x: visible !important;
      min-height: 426px !important;
    }
  }
}
