.footer {
  background-color: #fcf4ed;
  .container {
    max-width: 1200px;
    margin: auto;
    position: relative;
    text-align: center;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      gap: 18px;
      padding-left: 8px;
      padding-right: 8px;
    }
    .left-side {
      text-align: left;
      min-width: 300px;
    }
    .right-side {
      display: flex;
      text-align: right;
      gap: 50px;
      width: -webkit-fill-available;
      flex-direction: row-reverse;
      @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
        gap: 18px;
      }
      .menu-section {
        text-align: left;
        ul {
          margin: 0px;
          @media screen and (max-width: 900px) {
            padding-inline-start: 0px;
          }
          li {
            list-style: none;
            margin-top: 4px;
            a {
              color: #333333;
              font-size: 1em;
              text-transform: capitalize;
            }
          }
        }
      }
      p {
        line-height: normal;
        margin: 0px;
        padding-bottom: 8px;
      }
      .header {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .paymentMethods {
    max-width: 200px;
    .payments {
      height: 30px;
    }
  }
  .socials {
    padding-top: 10px;
    p {
      line-height: normal;
      margin: 0px;
      padding-bottom: 8px;
    }
    .social-links {
      display: flex;
      gap: 8px;
      .social_link {
        height: 35px;
      }
    }
  }

  .logo {
    height: 30px;
    text-align: left;
    &.center {
    }
    img {
      text-align: left;
    }
  }
}
iframe#webpack-dev-server-client-overlay {
  display: none;
}
