.profileAddressBook {
  padding-top: 70px;
  height: 100vh;
  max-width: 600px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 70px 10px 0px;
  .primaryAddress {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .addressItem {
      width: 280px;
    }
  }

  h2 {
    text-align: center;
  }

  .addAddress {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    width: 100%;
    background: #c3f3e3;
    height: 48px;
    border-radius: 8px;
    color: #333;
    text-decoration: none;
    flex-shrink: 0;
    max-width: 280px;
    .circle {
      background: #fff;
      border-radius: 100%;
      height: 24px;
      width: 24px;
      margin: 0px 10px 0px;
      line-height: 24px;
      text-align: center;
      justify-content: center;
      display: flex;
      font-weight: bold;
      font-size: 14px;
      color: #18b2bb;
    }

    @media screen and (max-width: 480px) {
      max-width: calc(50% - 10px);
    }
  }

  .book {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    width: 100%;

    //checkbox
    .checkBoxWrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 0 0 calc(33.3% - 10px);

      @media screen and (max-width: 480px) {
        flex: 0 0 calc(50% - 10px);
      }

      input {
        position: absolute;
        left: 10px;
        border: 0px;
        outline: 0;

        &:checked + label {
          background: #c3f3e3;
        }

        &:checked + .price {
          opacity: 1;
        }
      }
    }
  }
}
.addressItemGroupButton {
  display: flex;
  position: absolute;
  top: 0px;
  right: 0px;
}
.addressItemEditLink {
  color: black;
}
.addressItem {
  position: relative;
  flex: 0 0 calc(50% - 10px);

  @media screen and (max-width: 480px) {
    flex: 0 0 calc(100% - 10px);
  }
  input {
    position: absolute;
    left: 10px;
    border: 0px;
    outline: 0;

    &:checked + label {
      background: #c3f3e3;
    }

    &:checked + .price {
      opacity: 1;
    }
  }

  &Edit {
    display: inline-block;
    padding: 10px;
    border-radius: 0px 8px;
    font-size: 16px;
    color: #333;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: #fff;
      background: #4af1ba;
    }
  }

  &Delete {
    display: inline-block;
    padding: 10px;
    border-radius: 0px 8px;
    font-size: 16px;
    color: #333;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: #fff;
      background: #ed7b6d;
    }
  }
}

.addressTile {
  left: 0px;
  width: 100%;
  max-width: 280px;
  height: 100%;
  padding: 10px 0px 0px;
  background: #fcf4ed;
  border-radius: 8px;
  user-select: none;
  justify-items: center;
  flex-grow: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  &:hover {
    background: #c3f3e3;
    > .makePrimaryAddress {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  .makePrimaryAddress {
    position: relative;
    bottom: 0px;
    left: 0px;
    line-height: 30px;
    width: 100%;
    color: #333;
    display: flex;
    border-top: 1px solid #fff;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    border-radius: 0px 0px 8px 8px;
    span {
      line-height: normal;
      font-size: 16px;
    }
    p {
      margin: 0px;
      line-height: 30px;
    }
  }

  .ion-icon {
    font-size: 24px;
  }

  &.true {
    background: #c3f3e3;
    align-items: center;
    padding: 10px;
    flex-direction: row;
    .price {
      opacity: 1;
    }
  }

  .price {
    opacity: 0;
    text-align: right;
    color: #18b2bb;
  }

  .addressDetails {
    width: calc(100% - 20px);
    margin-left: 20px;
    min-height: 130px;

    div {
      margin: 3px;
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
}
