.circleLogo {
  width: 140px;
  height: 140px;
  background: black;
  display: flex;
  margin: 0px auto;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  svg {
    position: relative;
    margin-left: -5px;
    filter: invert(1);
    width: 82% !important;
  }
}
