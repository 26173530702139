.invoiceContainer {
  display: flex;
  justify-content: center;
}
.invoicePage {
  border: 1px solid #eee;
  padding: 40px;
  margin: 20px;
  width: 21cm;
  height: 29.7cm;
  zoom: 0.8;
  @media screen and (max-width: 480px) {
    zoom: 0.45;
    margin: 180px auto;
  }
  .invoiceHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 100px;
    align-items: self-end;
    .address {
      text-align: left;
    }
    .logo {
      max-width: 100px;
      flex-grow: 0;
      margin-bottom: 20px;
    }
  }

  .date {
    text-align: right;
  }
  .colorSpan {
    color: #999;
  }
  table,
  tbody {
    margin: 20px auto;
    border-collapse: collapse;
    border: 1px solid #fcf4ed;
    max-width: 480px;
    width: 100%;
  }
  th {
    background-color: #fcf4ed;
    font-weight: bold;
  }
  td,
  th {
    line-height: 36px;
    text-align: left;
    padding: 5px 5px 5px 10px;
    // width:33%;
  }
  td {
    padding: 10px 20px;
    &:first-child {
      text-align-last: left;
    }
    &:last-child {
      text-align: right;
    }
    small {
      color: #aaa;
      margin-left: 10px;
    }
  }
  .uppercase,
  .payment {
    text-transform: uppercase;
  }
  .total {
    border-top: 1px solid #fcf4ed;
  }
  .title {
    border-bottom: 1px solid #fcf4ed;
    margin: 0px;
    background: #fcf4ed;
    h4 {
      text-align: right;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
      margin: 0px;
    }
  }
  .address {
    // width:50%;
    td {
      line-height: 24px;
    }
  }
}

.invoiceLayout {
  position: relative; /* Change from fixed to relative */
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 100vh; /* Limit height to viewport */
  z-index: 500;
  background: #fff;
  width: 100%;
  .close {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 56px;
    z-index: 1000;
    cursor: pointer;
  }
}
